import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import FormCcpa from '../components/formCcpa'

const LegalTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage="null"
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <p className="font-alt font-w-700 letter-spacing-1 text-medium"> Last updated: {post.frontmatter.date} </p>
                <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      {
      post.fields.slug == "/do-not-sell-my-personal-information/" && 
      <FormCcpa />
      }
    </Layout>
  )
}
export default LegalTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {   
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "legal" } }
    ) {
      fields {
        slug 
      }
      html
      frontmatter {
        meta {
          title
          description 
        }
        title
        date(formatString: "MMMM DD, YYYY")
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
      }
    }
  }
`
